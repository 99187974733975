<template>
  <div class="borad_view">
    <div class="header">
      <div class="title">{{ this.board_title }}</div>
      <div class="info">
        {{
          this.common.date_long_format(this.common.date_parse(this.board_date))
        }}
      </div>
    </div>
    <div class="body" v-html="this.board_content"></div>
    <div class="footer">
      <button class="button middle" @click="click_goto_list">
        {{ this.$t("common.goto_list") }}
      </button>
    </div>
  </div>

  <Spinner v-show="processing" />
</template>

<script>
export default {
  name: "BoardView",
  metaInfo() {
    return {
      title: this.$t("meta.title") + " :: " + this.board_title,
      og: {
        title: this.$t("meta.title") + " :: " + this.board_title,
      },
    };
  },
  data: function () {
    return {
      process_queue: [],
      board_id: 0,
      board_title: "",
      board_date: "",
      read_count: 0,
      writer: "",
      board_content: "",
    };
  },
  computed: {
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },
  },
  mounted() {
    this.board_id = this.$route.params.board_id;
    this.load_notice_data();
  },
  methods: {
    load_notice_data: function () {
      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/board/get",
          {
            board_id: self.board_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          if (response.data.result != "OK") {
            self.$swal
              .fire({
                text: self.$t("common.error_occur"),
                icon: "error",
              })
              .then(() => {
                self.$router.back();
              });
            return;
          }

          let data = response.data.data;

          self.board_title = data.board_title;
          self.board_content = data.board_detail;
          self.read_count = data.read_count;
          self.board_date = data.register_date;
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //!-- axios
    }, //-- load_notice_data

    click_goto_list: function () {
      this.$router.push("/notice");
    },
  },
};
</script>

<style scoped>
.borad_view {
  border-top: 1px solid #dedede;
}
.borad_view > .header {
  padding: 0;
}
.borad_view > .header > .title {
  font-size: 1.2rem;
  font-weight: 700;
  padding: 0.8rem 0;
  border-bottom: 1px solid #efefef;
}
.borad_view > .header > .info {
  font-size: 1rem;
  font-weight: 400;
  padding: 0.8rem 0;
  color: #808080;
  border-bottom: 1px solid #dedede;
}
.borad_view > .body {
  position: relative;
  padding: 0.8rem 0;
  border-bottom: 1px solid #dedede;
}
.borad_view > .body:deep(img) {
  max-width: 100%;
}
.borad_view > .footer {
  padding: 1rem 0;
  text-align: center;
}
.borad_view > .footer > button {
  margin: 0 0.4rem;
}
</style>
